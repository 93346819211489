/* SPDX-License-Identifier: AGPL-3.0-or-later */
/* global searxng */
(function (w, d, searxng) {
  "use strict";
  function addDropDownClickHandler (dropDownElement, chevron) {
    searxng.addClickAndKeyboardListener(dropDownElement, function () {
      const isOpen = dropDownElement.classList.toggle("open");
      chevron.classList.toggle("down", isOpen);
      chevron.classList.toggle("up", !isOpen);
    });
  }

  function addDropDownOptionClickHandler (optionsWrapper, customSelect, customSelectTrigger, chevron, hiddenInput) {
    for (const option of optionsWrapper) {
      searxng.addClickAndKeyboardListener(option, function (event) {
        event?.stopPropagation?.();
        for (const opt of optionsWrapper) {
          opt.classList.remove("selected");
        }
        option.classList.add("selected");
        customSelectTrigger.textContent = option.textContent;
        hiddenInput.value = option.getAttribute("data-value");
        customSelect.classList.remove("open");
        chevron.classList.replace("up", "down");
        if (searxng.endpoint === "results") {
          searxng.submitIfQuery();
        }
      });
    }
  }

  function closeDropDownOnOutsideClick (dropDownElement, chevron) {
    d.addEventListener("click", function (event) {
      if (!dropDownElement.contains(event.target)) {
        dropDownElement.classList.remove("open");
        chevron.classList.remove("up");
        chevron.classList.add("down");
      }
    });
  }

  function addSelectedAttributeToOption (optionsWrapper, customSelectTrigger, hiddenInput) {
    const initialValue = hiddenInput.value;
    for (const option of optionsWrapper) {
      if (option.getAttribute("data-value") === initialValue) {
        option.classList.add("selected");
        customSelectTrigger.textContent = option.textContent;
      }
    }
  }

  function createDropDownHandle (wrapperSelector) {
    const customSelectWrapper = d.querySelector(wrapperSelector);
    const customSelect = customSelectWrapper.querySelector(".custom-select");
    const customSelectTrigger = customSelect.querySelector(".custom-select-trigger");
    const customOptions = customSelect.querySelectorAll(".custom-option");
    const hiddenInput = d.querySelector(`input[name=${customSelectWrapper.dataset.selectId}]`);
    const chevron = customSelect.querySelector(".chevron");

    addDropDownClickHandler(customSelect, chevron);
    addDropDownOptionClickHandler(customOptions, customSelect, customSelectTrigger, chevron, hiddenInput);
    closeDropDownOnOutsideClick(customSelect, chevron);
    addSelectedAttributeToOption(customOptions, customSelectTrigger, hiddenInput);

    chevron.classList.add("down");
  }

  searxng.customSelect = createDropDownHandle;
})(window, document, searxng);
