/* SPDX-License-Identifier: AGPL-3.0-or-later */
/* global lottie */
(function (w, d, searxng) {
  'use strict';

  if (searxng.endpoint !== 'results') {
    return;
  }
  const qinput_id = "q";

  function hideToolsBar () {
    const toolsCheckbox = d.getElementById('checkbox_tools');
    const toolsBar = d.getElementsByClassName('search_filters')[0];
    toolsCheckbox.addEventListener('change', function () {
      toolsBar.classList.toggle('hide');
    });
  }

  function typeWriterEffect (paragraphs) {
    const typeSpeed = 15;

    function typeWriter (text, element, callback) {
      let i = 0;
      element.innerHTML = ''; // Clear existing content
      element.style.visibility = 'visible';

      function type () {
        if (i < text.length) {
          element.innerHTML += text.charAt(i);
          i++;
          setTimeout(type, typeSpeed);
        } else if (callback) {
          callback();
        }
      }

      type();
    }

    function startTypingAnimation (paragraphs) {
      const container = d.querySelector('.genie-answer-content');
      stopLottieAnimation();
      let i = 0;

      function next () {
        if (i < paragraphs.length) {
          const p = d.createElement('p');
          p.className = 'type';
          container.appendChild(p);
          typeWriter(paragraphs[i], p, next);
          i++;
        }
      }
      next();
    }

    startTypingAnimation(paragraphs);
  }

  function stopLottieAnimation () {
    const container = d.querySelector('.genie-answer-content');
    container.classList.remove('loading');
    d.querySelector('lottie-player').remove();
  }

  function writeGenieAnswerFromCache (answer) {
    stopLottieAnimation();
    answer.forEach(paragraph => {
      const p = d.createElement('p');
      p.textContent = paragraph;
      d.querySelector('.genie-answer-content').appendChild(p);
    });
  }

  async function fetchGenieAnswer (searchTerm) {
    try {
      const storedData = getFromSessionStorage('lastAnswer');

      if (searchTerm === storedData?.lastSearchTerm && isCacheValid(storedData?.timeStamp)) {
        writeGenieAnswerFromCache(storedData.lastSearchAnswer);
        return;
      }

      const response = await fetch('/get_genie_answer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ searchTerm })
      });

      if (response.ok) {
        const data = await response.json();
        storeInSessionStorage('lastAnswer', {
          lastSearchTerm: searchTerm,
          lastSearchAnswer: data,
          timeStamp: Date.now()
        });
        typeWriterEffect(data);
      } else {
        console.error('Failed to fetch genie answer');
      }
    } catch (error) {
      console.error('Error fetching genie answer:', error);
    }
  }

  function storeInSessionStorage (key, value) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error storing in session storage:', error);
    }
  }

  function getFromSessionStorage (key) {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (error) {
      console.error('Error getting from session storage:', error);
      return null;
    }
  }

  function isCacheValid (timestamp) {
    const TTL = 1000 * 60 * 10;// 10 minutes
    const timeDifference = Date.now() - timestamp;
    return timeDifference < TTL;
  }

  function validateForm () {
    const qinput = d.getElementById(qinput_id);
    if (!qinput.value.trim()) {
      qinput.value = new URLSearchParams(window.location.search).get("q");
    }
  }

  searxng.ready(function () {
    d.addEventListener('submit', validateForm);
    hideToolsBar();
    searxng.customSelect('.custom-select-wrapper[data-select-id="time_range"]');
    searxng.customSelect('.custom-select-wrapper[data-select-id="safesearch"]');

    const qinput = d.getElementById(qinput_id);
    const selected_category = d.getElementsByClassName('category category_button selected')[0]?.textContent;

    if (qinput && selected_category && selected_category.trim() === 'all') {
      fetchGenieAnswer(qinput.value);
    }

    if (d.querySelector('#search_url button#copy_url')) {
      d.querySelector('#search_url button#copy_url').style.display = "block";
    }

    searxng.on('.btn-collapse', 'click', function () {
      const btnLabelCollapsed = this.getAttribute('data-btn-text-collapsed');
      const btnLabelNotCollapsed = this.getAttribute('data-btn-text-not-collapsed');
      const target = this.getAttribute('data-target');
      const targetElement = d.querySelector(target);
      let html = this.innerHTML;
      if (this.classList.contains('collapsed')) {
        html = html.replace(btnLabelCollapsed, btnLabelNotCollapsed);
      } else {
        html = html.replace(btnLabelNotCollapsed, btnLabelCollapsed);
      }
      this.innerHTML = html;
      this.classList.toggle('collapsed');
      targetElement.classList.toggle('invisible');
    });

    searxng.on('.media-loader', 'click', function () {
      const target = this.getAttribute('data-target');
      const iframe_load = d.querySelector(target + ' > iframe');
      const srctest = iframe_load.getAttribute('src');
      if (srctest === null || srctest === undefined || srctest === false) {
        iframe_load.setAttribute('src', iframe_load.getAttribute('data-src'));
      }
    });

    searxng.on('#copy_url', 'click', function () {
      const target = this.parentElement.querySelector('pre');
      navigator.clipboard.writeText(target.innerText);
      this.innerText = this.dataset.copiedText;
    });

    searxng.selectImage = function (resultElement) {
      /* eslint no-unused-vars: 0 */
      if (resultElement) {
        // load full size image in background
        const imgElement = resultElement.querySelector('.result-images-source img');
        const thumbnailElement = resultElement.querySelector('.image_thumbnail');
        const detailElement = resultElement.querySelector('.detail');
        if (imgElement) {
          const imgSrc = imgElement.getAttribute('data-src');
          if (imgSrc) {
            const loader = d.createElement('div');
            const imgLoader = new Image();

            loader.classList.add('loader');
            detailElement.appendChild(loader);

            imgLoader.onload = e => {
              imgElement.src = imgSrc;
              loader.remove();
            };
            imgLoader.onerror = e => {
              loader.remove();
            };
            imgLoader.src = imgSrc;
            imgElement.src = thumbnailElement.src;
            imgElement.removeAttribute('data-src');
          }
        }
      }
      d.getElementById('results').classList.add('image-detail-open');
      searxng.scrollPageToSelected();
    }

    searxng.closeDetail = function (e) {
      d.getElementById('results').classList.remove('image-detail-open');
      searxng.scrollPageToSelected();
    }
    searxng.on('.result-detail-close', 'click', e => {
      e.preventDefault();
      searxng.closeDetail();
    });
    searxng.on('.result-detail-previous', 'click', e => {
      e.preventDefault();
      searxng.selectPrevious(false)
    });
    searxng.on('.result-detail-next', 'click', e => {
      e.preventDefault();
      searxng.selectNext(false);
    });

    w.addEventListener('scroll', function () {
      const e = d.getElementById('backToTop'),
        scrollTop = d.documentElement.scrollTop || d.body.scrollTop,
        results = d.getElementById('results');
      if (e !== null) {
        if (scrollTop >= 100) {
          results.classList.add('scrolling');
        } else {
          results.classList.remove('scrolling');
        }
      }
    }, true);

  });

})(window, document, window.searxng);
