/* SPDX-License-Identifier: AGPL-3.0-or-later */
/* global searxng */
(function (w, d, searxng) {
  "use strict";
  function addClickAndKeyboardListener (element, callback) {
    for (const eventName of ["click", "keydown"]) {
      element.addEventListener(eventName, function (event) {
        if (eventName === "keydown" && !["Enter", "Spacebar", " "].includes(event.key)) {
          return;
        }
        callback(event);
      });
    }
  }

  searxng.addClickAndKeyboardListener = addClickAndKeyboardListener;
})(window, document, searxng);
